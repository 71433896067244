import { createStore, Store, useStore as baseUseStore } from "vuex";
import useAuth0, { Auth0Plugin } from "@/utilities/authorization/useAuth0";
import { InjectionKey } from "vue";
import { AUser } from "@/services/useUser";
import { Project } from "@/services/project/useProject";
import { Toast } from "@/services/useToast";

const auth = useAuth0();
auth.createClient();

// Type definitions so store values are not 'any'
export interface State {
  auth: Auth0Plugin;
  isMobile: boolean;
  viewWidth: number;
  user: null | AUser;
  project: null | Project;
  toast: null | Toast;
}

// Key to be used to get typings by useStore()
export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: {
    auth,
    user: null,
    isMobile: false,
    viewWidth: 0,
    project: null,
    toast: null,
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});

export function useStore() {
  return baseUseStore(key);
}
