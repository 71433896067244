import createAuth0Client, {
  Auth0Client,
  GetTokenSilentlyOptions,
  LogoutOptions,
  RedirectLoginOptions,
  User,
} from "@auth0/auth0-spa-js";
import { reactive, ref } from "vue";
import router from "@/router";

const domain = process.env.VUE_APP_AUTH0_DOMAIN; // The domain on Auth0 of the tenant
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID; // The client ID of the specific application
const audience = process.env.VUE_APP_AUTH0_AUDIENCE; // The domain of the specific Auth0 API
const callbackUrl = process.env.VUE_APP_AUTH0_CALLBACK_URL; // The domain to redirect too after sign-in
const rootURL = process.env.VUE_APP_AUTH0_ROOT_URL;

/**
 * The provided Auth0 interface
 */
export interface Auth0Plugin {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: User | null;
  error: null | Error;
  ready: boolean;
  createClient: () => Promise<void>;
  handleCallback: () => Promise<void>;
  login: (options?: RedirectLoginOptions) => Promise<void>;
  logout: (options?: LogoutOptions) => Promise<void>;
  getAccessToken: () => Promise<null | string>;
}

/**
 * Initialize auth0 and create the user client
 */
export default function (): Auth0Plugin {
  const auth0Client = ref<Auth0Client | null>(null);

  const plugin = reactive<Auth0Plugin>({
    isAuthenticated: false,
    isLoading: true,
    user: null,
    error: null,
    ready: false,
    /**
     * Create the Auth0 Client
     */
    createClient: async (): Promise<void> => {
      auth0Client.value = await createAuth0Client({
        domain: domain,
        client_id: clientId,
        audience: audience,
        redirect_uri: callbackUrl,
      });
      plugin.ready = true;
    },
    /**
     * Handles redirects from the external auth0 login page
     * -> If the user was already logged in redirect them to the home page
     * -> Otherwise if there was an error log it
     * -> Otherwise attempt to handel a custom callback (Like if the user logged in and is now returning)
     */
    handleCallback: async (): Promise<void> => {
      if (!auth0Client.value) {
        console.error("Tried to call handelCallback without auth0Client initialized");
        return;
      }

      plugin.isAuthenticated = await auth0Client.value.isAuthenticated();

      // If successfully logged in get the user and return
      if (plugin.isAuthenticated) {
        plugin.user = (await auth0Client.value.getUser()) || null;
        plugin.isLoading = false;
        const params = new URLSearchParams(window.location.search);
        if (params.has("project") && params.has("group"))
          router.push(
            params.has("version")
              ? `?project=${params.get("project")}&group=${params.get("group")}&version=${params.get("version")}`
              : `?project=${params.get("project")}&group=${params.get("group")}`
          );
        else if (params.has("menu")) router.push(`?menu=${params.get("menu")}`);
        else router.push("");
        return;
      }

      //Otherwise search the URL params to get the error message or response code
      const params = new URLSearchParams(window.location.search);
      const hasError = params.has("error");
      const hasCode = params.has("code");
      const hasState = params.has("state");

      // If error create a TS error
      if (hasError) {
        plugin.error = new Error(params.get("error_description") || "error completing login process");
        console.error(plugin.error);
        plugin.isLoading = false;
        return;
      }

      // Otherwise try to handle custom response
      if (hasCode && hasState) {
        try {
          const result = await auth0Client.value.handleRedirectCallback();

          // If the redirect logged the user in
          plugin.isAuthenticated = await auth0Client.value.isAuthenticated();

          if (plugin.isAuthenticated) {
            plugin.user = (await auth0Client.value.getUser()) || null;
            plugin.error = null;

            plugin.isLoading = false;

            // Check if the router has a specified url to re-direct too or push home
            let url = "/";
            if (result.appState && result.appState.targetUrl) {
              url = result.appState.targetUrl;
            }
            await router.push(url);

            return;
          }
        } catch (err) {
          if (err instanceof Error) plugin.error = err;
          console.error(plugin.error);
        }
      }
      plugin.isLoading = false;
    },
    /**
     * Redirect user to the login page
     * @param options
     */
    login: async (options?: RedirectLoginOptions): Promise<void> => {
      if (!auth0Client.value) {
        console.error("Tried to call login without auth0Client initialized");
        return;
      }

      try {
        await auth0Client.value.loginWithRedirect(options);
      } catch (err) {
        if (err instanceof Error) plugin.error = err;
      }
    },

    /**
     * Log user out
     */
    logout: async (): Promise<void> => {
      if (!auth0Client.value) {
        console.error("Tried to call logout without auth0Client initialized");
        return;
      }

      try {
        const options: LogoutOptions = { returnTo: rootURL };
        auth0Client.value.logout(options);
        plugin.user = null;
        plugin.isAuthenticated = false;
      } catch (err) {
        if (err instanceof Error) plugin.error = err;
      }
    },

    /**
     * Get an access token to authenticate a users API request (NOTE: Tokens should not be saved or stored)
     * @param options
     * @returns The users authentication token to attach to a request
     */
    getAccessToken: async (options?: GetTokenSilentlyOptions): Promise<null | string> => {
      if (!auth0Client.value) {
        console.error("Tried to call getAccessToken without auth0Client initialized");
        return null;
      }

      return (await auth0Client.value.getTokenSilently(options)) as string;
    },
  });

  return plugin;
}
