import NotFound from "@ai-nc/a-components/src/pages/NotFound.vue";
import authenticationGuard from "@/utilities/authorization/authenticationGuard";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { computed } from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/denied",
    name: "Access Denied",
    component: NotFound,
  },
  {
    path: "/",
    name: "Home",
    component: () => import("@/pages/HomeDashboard.vue"),
    beforeEnter: authenticationGuard,
  },
  {
    path: "/callback",
    name: "Callback",
    redirect: "/",
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/pages/UserProfile.vue"),
    props: (route) => ({ menu: route.query.menu }),
    beforeEnter: authenticationGuard,
  },
  {
    path: "/project",
    name: "Project View",
    component: () => import("@/pages/ProjectView.vue"),
    props: (route) => ({ group: route.query.group, project: route.query.project, version: route.query.version }),
    beforeEnter: authenticationGuard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

export const menuRoutes = computed(() => {
  return {
    top: [
      { name: "Home", path: "/", icon: "home" },
      { name: "Projects", path: "/project", icon: "document-report" },
    ],
    bottom: [
      {
        name: "Profile",
        path: "/profile?menu=account",
        icon: "user",
        subItems: [
          { name: "Account", path: "/profile?menu=account", icon: "cog" },
          { name: "Groups", path: "/profile?menu=group", icon: "user-group" },
          { name: "Warnings", path: "/profile?menu=warnings", icon: "adjustments" },
          { name: "Sign Out", path: "/profile?menu=sign-out", icon: "logout", color: "text-alert-error" },
        ],
      },
    ],
  };
});
