import { useStore } from "@/store";
import { reactive } from "vue";
import { v4 as uuidv4 } from "uuid";

/** Default toast lifespan in ms */
const DEFAULT_TOAST_LIFE = 5000;
/** Toast fade out time in ms */
const TOAST_FADE_TIME = 300;

/** Get the toast object, or create it if it does not exist */
export default function () {
  const store = useStore();
  if (!store.state.toast) store.state.toast = new Toast();
  return store.state.toast;
}

export class Toast {
  readonly toasts = reactive<
    Record<
      string,
      {
        severity: "info" | "warn" | "error";
        lifespan?: number;
        title: string;
        message: string;
        new?: boolean;
        fading?: boolean;
      }
    >
  >({});

  post(message: { severity: "info" | "warn" | "error"; title: string; message: string; lifespan?: number }) {
    const uuid = uuidv4();
    this.toasts[uuid] = message;
    this.toasts[uuid].new = true;
    setTimeout(() => {
      this.toasts[uuid].new = false;
      this.updateToast(uuid);
    }, 50);
  }

  delete(uuid: string) {
    if (uuid in this.toasts) delete this.toasts[uuid];
    else console.warn("Tried to delete toast that does not exist");
  }

  private updateToast(uuid: string) {
    if (!(uuid in this.toasts)) return;
    if (this.toasts[uuid].fading) delete this.toasts[uuid];
    else {
      const lifespan = this.toasts[uuid].lifespan;
      if (!lifespan) {
        this.toasts[uuid].lifespan = -1;
        setTimeout(() => this.updateToast(uuid), DEFAULT_TOAST_LIFE);
      } else if (lifespan < 0) {
        this.toasts[uuid].fading = true;
        setTimeout(() => this.updateToast(uuid), TOAST_FADE_TIME);
      } else {
        this.toasts[uuid].lifespan = -1;
        setTimeout(() => this.updateToast(uuid), lifespan);
      }
    }
  }
}
