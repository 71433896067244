import { useStore } from "@/store";
import { Auth0Plugin } from "@/utilities/authorization/useAuth0";
import axios from "axios";
import { reactive, watch } from "vue";
import * as Dashboard from "./types/Dashboard";
import { RawResponse } from "./project/Part";
import { AVector3 } from "./types/Utility";

export const BASE_URL = process.env.VUE_APP_AI_NC_API;
const FLOAT_ROUND = 10 ** -7;

export type SlackBlock = ActionsBlock | ContextBlock | SectionBlock;

export type ActionsBlock = {
  type: "actions";
  elements: ButtonElement[];
};

export type ContextBlock = {
  type: "context";
  elements: TextElement[];
};

export type SectionBlock =
  | {
      type: "section";
      fields: TextElement[];
    }
  | {
      type: "section";
      text: TextElement;
    };

export type ButtonElement = {
  type: "button";
  text: TextElement;
  action_id: string;
  url?: string;
  style?: "primary" | "danger";
};

export type TextElement = {
  type: "plain_text" | "mrkdwn";
  text: string;
};

/**
 * If the user is logged in call the user creation immediately, otherwise watch and wait until the user is
 * authenticated
 */
export function createUser() {
  const store = useStore();
  if (store.state.user) return;
  const auth = store.state.auth;
  watch(
    () => auth.user,
    () => {
      if (auth.user && !store.state.user) {
        store.state.user = reactive(new AUser(auth));
      }
    },
    { immediate: true }
  );
}

/** Get the user in order to access settings or make API calls */
export default function () {
  const store = useStore();
  if (!store.state.user) throw Error("Tried to get user before initialization");
  return store.state.user;
}

const DEFAULT_SETTINGS: Dashboard.UserSettings = {
  darkMode: false,
  warnings: {
    tolerance: { minimum: { 1: 0.0001, 2: 0.00002, 3: 0.00001 }, ignore: false },
    roughness: { minimum: { 1: 0.0000001, 2: 0.00000002, 3: 0.00000001 }, ignore: false },
    tapped: { maximum: { 1: 1, 2: 5, 3: 10 }, ignore: false },
    stock: { maximum: { xy: { 1: 0.3, 2: 0.5, 3: 1 }, z: { 1: 0.2, 2: 0.3, 3: 0.5 } }, ignore: false },
    weight: { maximum: { 1: 10000, 2: 20000, 3: 30000 }, ignore: false },
    stockWeight: { maximum: { 1: 15000, 2: 25000, 3: 35000 }, ignore: false },
    materialRemoved: { maximum: { 1: 0.8, 2: 0.9, 3: 0.95 }, ignore: false },
    sharpCorner: { severity: 3, ignore: false },
    smallRadius: { ratio: { 1: 3, 2: 5, 3: 6 }, minimum: { 1: 2, 2: 1, 3: 0.5 }, ignore: false },
    narrowHole: { ratio: { 1: 5, 2: 7, 3: 10 }, minimum: { 1: 2, 2: 1, 3: 0.5 }, ignore: false },
    holeDiameter: { modulus: { 2: 0.1, 3: 0.05 }, ignore: false },
    flatHole: { severity: 2, ignore: false },
    drillTipAngle: { severity: 3, allowed: [(118 / 180) * Math.PI, (135 / 180) * Math.PI], ignore: false },
    chamferAngle: { severity: 2, allowed: [(30 / 180) * Math.PI, (45 / 180) * Math.PI], ignore: false },
    drillQuantity: { maximum: { 2: 4, 3: 6 }, ignore: false },
    smallRadiiQuantity: { maximum: { 2: 4, 3: 6 }, ignore: false },
  },
  defaultRounding: 2,
};

/** A class representing a user and handling all of the the API calls and data tied to that user */
export class AUser {
  readonly _auth: Auth0Plugin;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly _userMetadata: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly _appMetadata: Record<string, any>;
  settings: Dashboard.UserSettings;
  userInfo: Dashboard.UserInfo;
  tasks: Dashboard.TaskItem[];
  projects: Record<string, Dashboard.ProjectSummary[]>;
  projectsLoaded: boolean;
  readonly id: string;
  readonly personalGroup: string;

  constructor(auth: Auth0Plugin) {
    if (!auth.user) throw Error("Error: tried to create AUser before auth0 user loaded");
    this._auth = auth;
    this._userMetadata = auth.user.userMetadata;
    this._appMetadata = auth.user.appMetadata;

    if (auth.user.sub) this.id = auth.user.sub;
    else throw new Error("Tried to create but user id missing (auth.user.sub)");

    // Initialize tasks and update user metadata if they don't exist
    if (!this._userMetadata.tasks) {
      this.request("PATCH", BASE_URL + "api/v2/users/user_metadata", { user_id: this.id }, { tasks: [] });
      this.tasks = reactive([]);
    } else {
      this.tasks = reactive(this._userMetadata.tasks.filter((value: Dashboard.TaskItem) => !value.completed));
    }
    watch(this.tasks, () => {
      // TODO: stop this spamming the endpoint if there are many updates
      this.request("PATCH", BASE_URL + "api/v2/users/user_metadata", { user_id: this.id }, { tasks: this.tasks });
    });

    // Initialize settings and update user metadata if they don't exist
    if (!this._userMetadata.settings) {
      this.request(
        "PATCH",
        BASE_URL + "api/v2/users/user_metadata",
        { user_id: this.id },
        { settings: DEFAULT_SETTINGS }
      );
      this.settings = reactive(DEFAULT_SETTINGS);
    } else {
      this.settings = reactive(this._userMetadata.settings);
    }
    watch(this.settings, () => {
      // TODO: stop this spamming the endpoint if there are many updates
      this.request("PATCH", BASE_URL + "api/v2/users/user_metadata", { user_id: this.id }, { settings: this.settings });
    });

    this.userInfo = {
      name: auth.user.nickname ? auth.user.nickname : auth.user.name,
      image: auth.user.picture,
    };

    this.personalGroup = this._appMetadata.groups[0].group_id;

    this.projects = {};
    this.projects[this.personalGroup] = [];
    this.projectsLoaded = false;
  }

  async getProjects() {
    this.projectsLoaded = false;
    const response = await this.request("GET", BASE_URL + "api/v2/project", { group_id: this.personalGroup });
    this.projects = {};
    this.projects[this.personalGroup] = [];
    for (const p of Object.values(response.data.projects)) {
      const project: any = p;
      this.projects[this.personalGroup].push({
        name: project.title,
        score: 0,
        errors: 0,
        updated: project.updated ? project.updated : 1663024239000,
        created: 1663024239,
        version: project.models.length,
        id: project.project_id,
        group: project.group_id,
      });
    }
    this.projectsLoaded = true;
  }

  logout() {
    this._auth.logout();
  }

  round(value: number, placeOverride?: number) {
    return placeOverride
      ? Math.round(value * 10 ** placeOverride) / 10 ** placeOverride
      : Math.round(value * 10 ** this.settings.defaultRounding) / 10 ** this.settings.defaultRounding;
  }

  /**
   * Make a request to the AI-NC api as the user
   */
  async request(
    /** The REST method */
    method: "GET" | "PUT" | "POST" | "PATCH" | "DELETE",
    /** The url to make the request too */
    url: string,
    /** The query strings to apply to the request */
    queryStrings: Record<string, string> = {},
    /** The body of the request */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: any = null,
    /** The headers, Auth token automatically applied */
    headers: Record<string, string> = {}
  ) {
    let fullUrl = url + "?";
    for (const [key, value] of Object.entries(queryStrings)) fullUrl += key + "=" + value + "&";
    fullUrl = fullUrl.slice(0, -1);

    headers.Authorization = "Bearer " + (await this._auth.getAccessToken());

    const config = { headers: headers };

    let response;
    try {
      if (method === "GET") response = await axios.get(fullUrl, config);
      else if (method === "PUT") response = await axios.put(fullUrl, body, config);
      else if (method === "POST") response = await axios.post(fullUrl, body, config);
      else if (method === "PATCH") response = await axios.patch(fullUrl, body, config);
      else if (method === "DELETE") response = await axios.delete(fullUrl, config);
      else if (method === "TEST") {
        console.error("TEST Request: ", fullUrl, body, config);
        return { data: undefined, status: 200 };
      }
    } catch (error) {
      console.error(error);
      throw error;
    }

    if (!response?.status || ["4", "5"].includes(String(response.status)[0])) {
      console.error("Bad response", response);
      throw new Error();
    }

    return { status: response.status, data: response.data };
  }

  /** Get the highlights to show the user */
  async getHighlights(): Promise<Dashboard.Highlight[]> {
    return (await axios.get("/DELETE-ME/suggested-blogs.json")).data as Dashboard.Highlight[];
  }

  /** Create a new project given a .step file or load a project with the given id */
  async newProject(
    file?: File,
    params?: { material: string; tolerance?: number; roughness?: number; tapped: number },
    name?: string,
    id?: string,
    group_id?: string,
    holes?: boolean
  ): Promise<RawResponse> {
    const hole_url = holes ? BASE_URL + "holes/" : BASE_URL;
    if (!id) {
      if (!name || !file || !params) throw new Error("Tried to create new project without name, file and params");
      id = (await this.request("POST", hole_url + "api/v2/project", { name, group_id: this.personalGroup }, file)).data
        .project_id as string;
    }

    const response = await this.request("GET", hole_url + "api/v2/project", {
      group_id: group_id ? group_id : this.personalGroup,
      project_id: id,
    });

    let data, parameters;

    // If the project has not been loaded before, analyze TODO: Reenable when more stable
    // eslint-disable-next-line no-constant-condition
    if (!response.data.data || !response.data.volumeData || !response.data.updated || true) {
      if (!name || !file || !params) throw new Error("Could not load project data without");
      parameters = response.data.params;
      // eslint-disable-next-line no-constant-condition
      data = false // Disabled under heavy construction
        ? response.data.data
        : (
            await this.request(
              "POST",
              hole_url + "vade/file/analyse",
              { filename: file.name },
              await file.arrayBuffer()
            )
          ).data;

      const bounds: { max: AVector3; min: AVector3 } = {
        max: [data.bounds.max[0] / 1000, data.bounds.max[1] / 1000, data.bounds.max[2] / 1000],
        min: [data.bounds.min[0] / 1000, data.bounds.min[1] / 1000, data.bounds.min[2] / 1000],
      };
      const volume = data.model_params.part_volume / 10 ** 9;
      const surfaceArea = data.model_params.surface_area / 10 ** 6;

      parameters = { ...params, bounds, volume, surfaceArea };

      await this.request(
        "PUT",
        hole_url + "api/v2/project",
        { project_id: id },
        { data, params: parameters, updated: Date.now() }
      );
    } else {
      parameters = response.data.params;
      data = response.data.data;
    }

    const url: string = file
      ? URL.createObjectURL(file)
      : (await this.request("GET", hole_url + "api/v2/project/file", { project_id: id })).data.url;

    const ignore = ["BoreGeometry", "FloorGeometry", "LoopFillet", "CircRadius", "CounterSink"];
    const useful = ["DrilledBlindHole", "BlindHole", "ORing", "Pocket", "Boss", "ThroughHole"];

    // TODO: Figure out the formatting of the response
    const features: any[] = [];
    for (const feature of data.features) {
      if (useful.includes(feature.type)) {
        let newFeature: Record<string, any>;
        if (feature.type === "ORing" && holes) {
          features.push(feature);
        } else if (
          (feature.type === "DrilledBlindHole" || feature.type === "BlindHole" || feature.type === "ThroughHole") &&
          holes
        ) {
          features.push(feature);
        } else if (feature.type === "Pocket" && !holes) {
          features.push(feature);
        } // else console.warn("Unimplemented useful feature type: ", feature.type, feature);
      } else if (!ignore.includes(feature.type)) console.error("Unsorted feature type: ", feature.type);
    }
    this.getProjects();
    return { parameters, url, features, id } as RawResponse;
  }

  /** Get a part given its ID and a version */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async deleteProject(group: string, id: string): Promise<void> {
    await this.request("DELETE", BASE_URL + "api/v2/project", { project_id: id });
    this.getProjects();
  }

  async sendContact(text?: string, blocks?: SlackBlock[]) {
    if (!text && !blocks) throw new Error("Tried to send contact message without text or blocks");
    const body = text && blocks ? { text, blocks } : text ? { blocks } : { text: "New contact message", blocks };
    if (text || blocks)
      await axios.post(process.env.VUE_APP_SLACK_URL, JSON.stringify(body), {
        withCredentials: false,
        transformRequest: [
          (data, headers: any) => {
            delete headers.post["Content-Type"];
            return data;
          },
        ],
      });
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function kebabToCamel(kebab: Record<string, any>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const camel: Record<string, any> = {};
  for (const [key, value] of Object.entries(kebab)) {
    if (typeof value === "object") camel[camelString(key)] = kebabToCamel(value);
    else camel[camelString(key)] = value;
  }
  return camel;
}

function camelString(kebabString: string) {
  const camelString = kebabString
    .split("-")
    .map((e) => {
      return e[0].toUpperCase() + e.slice(1);
    })
    .join("");
  return camelString[0].toLowerCase() + camelString.slice(1);
}

function fixFloat(number: number) {
  return Math.round(number / FLOAT_ROUND) * FLOAT_ROUND;
}
