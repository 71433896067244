import { store } from "@/store";
import { watch } from "vue";
import { RouteLocationNormalized } from "vue-router";

/**
 * A wrapper to allow auth0 to load before navigation
 */
export default (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  /**
   * Ensure a user is allowed to navigate to a given page before re-routing
   * @param to The page the user is going to
   * @param from The page the user is coming from
   */
  const guardAction = function (to: RouteLocationNormalized, from: RouteLocationNormalized) {
    if (!to || !from) console.error("Redirect without destination and/or origin");
    if (store.state.auth.isAuthenticated) {
      return;
    }
    return false;
  };

  // If Auth0 is already loaded return the guard, otherwise wait until it is
  if (!store.state.auth.isLoading) {
    return guardAction(to, from);
  }

  watch(
    () => store.state.auth.isLoading,
    () => {
      if (!store.state.auth.isLoading) {
        return guardAction(to, from);
      }
    }
  );
};
